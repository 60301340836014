/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";
import { useState } from "@hookstate/core/dist";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { InformationInputs, WholesaleInputs } from "./validation";
import VuiUploadImage from "../../../../vodea/@vodea-ui/components/Forms/VuiUploadImage";
import VuiBreadcrumb from "../../../../vodea/@vodea-ui/components/VuiBreadcrumb";
import CategoryTokopediaBrandRepository from "../../../../repositories/Brand/Tokopedia/CategoryTokopediaBrandRepository";
import { defaultWeightOptions } from "../../../../constants";
import { AxiosError, AxiosResponse } from "axios";
import { $clone } from "../../../../vodea/utilities";
import {
  showToast,
  validateYoutubeUrl,
} from "../../../../vodea/utilities/helpers/global";
import { formatFormData } from "../../../../vodea/utilities/helpers/form";
import CategoryVariantRepository from "../../../../repositories/Brand/Tokopedia/CategoryVariantRepository";
import ShowcaseRepository from "../../../../repositories/Brand/Tokopedia/ShowcaseRepository";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import NumberFormat from "react-number-format";
import useRoleService from "../../../../vodea/utilities/hooks/useRoleService";
import ProductCommerceRepository from "../../../../repositories/Brand/Tokopedia/ProductCommerceRepository";
import VuiLoadingScreen from "../../../../vodea/@vodea-ui/components/VuiLoadingScreen";
import ProductRepository from "../../../../repositories/Brand/Tokopedia/ProductRepository";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import ReactSwitch from "react-switch";

interface RowVariantInterface {
  value_id?: any;
  name: any;
  sku: string;
  combination?: any;
  price?: number | string;
  stock?: number | string;
  pictures?: object | null;
  is_primary?: boolean;
}

const WholesaleBaseModel: WholesaleInputs = {
  min_qty: 0,
  price: 0,
};

const preOrderDayOptions = [
  { id: "DAY", name: "Hari" },
  { id: "WEEK", name: "Minggu" },
];

const ProductForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const arrVariant = useState<any[]>([]);
  const informationLoading = useState(false);
  const isMounted = useIsMounted();
  const categories = useState<any[]>([]);
  const showcases = useState<any[]>([]);
  const selectedShowcase = useState(null);
  const [pictureNull, setPictureNull] = React.useState(false);
  const title = `${id ? t("general.edit") : t("general.create")} ${t(
    "products.title"
  )}`;
  const [hasRender, setHasRender] = React.useState(false);
  const [showTableRow, setShowTableRow] = React.useState(false);
  const [onlyOnce, setOnlyOnce] = React.useState(true);
  const [categoryError, setCategoryError] = React.useState(false);
  const [skuError, setSkuError] = React.useState(false);
  const [showcaseError, setShowcaseError] = React.useState(false);
  const [nameError, setNameError] = React.useState(false);
  const [countSold, setCountSold] = React.useState<number>(0);
  const [priceError, setPriceError] = React.useState(false);
  const [minOrderError, setMinOrderError] = React.useState(false);
  const [weightError, setWeightError] = React.useState(false);
  const [durationError, setDurationError] = React.useState(false);
  const dimensionErrors = useState({
    length: false,
    width: false,
    height: false,
  });
  const isFetchingOnProduct = useState(false);
  const tokopediaProductId = useState(0);
  const stockProduct = useState(-1);
  const statusProduct = useState("EMPTY");

  const { brand } = useSelector((state: RootState) => state.brand);
  const { user } = useSelector((state: RootState) => state.system);

  const { isAdministrator, isKeyAccount } = useRoleService();

  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    control: informationControl,
    watch: informationWatch,
    setValue: informationSetValue,
  } = useForm<InformationInputs>({
    mode: "onChange",
  });

  const {
    fields: wholesaleFields,
    append: wholesaleAppend,
    remove: wholesaleRemove,
  } = useFieldArray({
    keyName: "key",
    control: informationControl,
    name: "wholesale",
  });

  const {
    fields: selectionFields,
    append: selectionAppend,
    remove: selectionRemove,
  } = useFieldArray({
    keyName: "key",
    control: informationControl,
    name: "selection",
  });

  const {
    fields: pictureFields,
    append: pictureAppend,
    remove: pictureRemove,
  } = useFieldArray({
    control: informationControl,
    name: "pictures",
  });

  const secondProductFields = useState<RowVariantInterface[]>([]);
  const preorder = informationWatch("preorder");
  const category = informationWatch("category_id");
  const selection = informationWatch("selection", []);

  const [secondPictureFields, setSecondPictureFields] = React.useState<any[]>([
    { url: null },
    { url: null },
    { url: null },
    { url: null },
    { url: null },
  ]);

  useMemo(() => {
    if (user) {
      const findTokopedia = user.current_brand_channels.find(
        (item: any) => item.channel_id == 1
      );
      if (findTokopedia && findTokopedia?.channel_meta?.is_tkpd_official) {
        setSecondPictureFields([
          { url: null },
          { url: null },
          { url: null },
          { url: null },
          { url: null },
          { url: null },
          { url: null },
        ]);
      }
    }
  }, [user]);

  const SortableItem = SortableElement(({ value, indexValue }: any) => (
    <VuiUploadImage
      className="picture-file"
      defaultValue={value}
      dragHandle={true}
      sizeUpdate={false}
      onChange={(val) => {
        for (let i = 0; i < secondPictureFields.length; i++) {
          if (!secondPictureFields[i].url) {
            setSecondPictureFields((items) => {
              const s = [...items];
              s[i] = val;
              return s;
            });
            break;
          }
        }
      }}
      onDelete={() => {
        const tempPictures: any[] = [...secondPictureFields];
        tempPictures[indexValue] = { url: null };

        for (let i = 0; i < tempPictures.length; i++) {
          if (!tempPictures[i]?.url && tempPictures[i + 1]?.url) {
            tempPictures[i] = tempPictures[i + 1];
            tempPictures[i + 1] = { url: null };
          }
        }

        setSecondPictureFields(tempPictures);
      }}
    />
  ));

  const SortableList = SortableContainer(({ items }: any) => {
    return (
      <div className={"form-stock-image"}>
        {items.map((value: any, index: number) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            indexValue={index}
            value={value}
          />
        ))}
      </div>
    );
  });

  const handleOnSortEnd = ({ newIndex, oldIndex }: any) => {
    setSecondPictureFields((items) => {
      return arrayMove(items, oldIndex, newIndex);
    });
  };

  const [selectedCategory1, setSelectedCategory1] = React.useState(null);
  const [selectedCategory2, setSelectedCategory2] = React.useState(null);
  const [selectedCategory3, setSelectedCategory3] = React.useState(null);

  const selectedCategory1Options = useState({
    id: 0,
    name: "",
    child: [],
  });

  const selectedCategory2Options = useState({
    id: 0,
    name: "",
    child: [],
  });

  const hasWholesale = useState(false);

  const renderTypeConditions = () => {
    return (
      <Controller
        render={({ onChange, value, name }) => (
          <div className="row">
            <div className="col-md-3">
              <div className={"form-check"}>
                <div className={"d-flex align-items-center"}>
                  <input
                    type="radio"
                    value={"NEW"}
                    name={name}
                    className="form-check-input"
                    onChange={onChange}
                    checked={value === "NEW"}
                  />
                  <label className={"form-check-label"}>
                    {t("products.form.conditionNew.label")}
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className={"form-check"}>
                <div className={"d-flex align-items-center"}>
                  <input
                    type="radio"
                    value="USED"
                    name={name}
                    className="form-check-input"
                    onChange={onChange}
                    checked={value === "USED"}
                  />
                  <label className={"form-check-label"}>
                    {t("products.form.conditionUsed.label")}
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
        name="condition"
        control={informationControl}
        defaultValue={"NEW"}
      />
    );
  };

  const getVariant = () => {
    CategoryVariantRepository.all(category.id, brand.id)
      .then((resp: any) => {
        arrVariant.set(resp.data.data.data.variant_details);
        setHasRender(true);
      })
      .catch((err: any) => {});
  };

  const getCategory = () => {
    CategoryTokopediaBrandRepository.all(brand.id)
      .then((resp: AxiosResponse) => {
        const data = resp.data.data.data.categories;
        categories.set(data);
      })
      .catch((err: AxiosError) => {});
  };

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    if (category?.id) {
      getVariant();
    }
  }, [category]);

  useEffect(() => {
    if (id && hasRender && onlyOnce) {
      setOnlyOnce(false);
    }
  }, [hasRender]);

  useEffect(() => {
    if (!id) {
      if (pictureFields.length <= 4) {
        pictureRemove();
        pictureAppend({ picture_id: null });
      }
    }
  }, []);

  const handleChangeVariantOptions = (value: any, index: number) => {
    let row: RowVariantInterface[] = [];
    const arr1 = index == 0 ? value : selection[0]?.options;
    const arr2 = index == 1 ? value : selection[1]?.options;

    if (selection?.length > 1 && arr2?.length > 0) {
      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
          const objectValue = secondProductFields.value.find(
            (a: any) =>
              a?.value_id === arr1[i].variant_unit_value_id + "-" + arr2[j].variant_unit_value_id ||
              a?.name[0] + "-" + a?.name[1] ===
                arr1[i]?.value + "-" + arr2[j]?.value
          );
          row.push({
            value_id: arr1[i].variant_unit_value_id + "-" + arr2[j].variant_unit_value_id,
            name: [arr1[i].value, arr2[j].value],
            combination: [i, j],
            price: _.get(objectValue, "price", ""),
            sku: _.get(objectValue, "sku", ""),
            pictures: $clone(_.get(objectValue, "pictures", {})),
            stock: _.get(objectValue, "stock", 0),
            is_primary: _.get(objectValue, "is_primary", i == 0 && j == 0),
          });
        }
      }
    } else if (selection?.length == 1 && arr1?.length > 0) {
      const newValues = arr1?.map((item1: any, indexItem1: number) => {
        const objectValue = secondProductFields.value.find(
          (a: any) => a.value_id === item1.variant_unit_value_id || a.name === item1.value
        );
        return {
          value_id: item1?.variant_unit_value_id,
          name: item1?.value,
          combination: [indexItem1],
          price: _.get(objectValue, "price", ""),
          sku: _.get(objectValue, "sku", ""),
          pictures: $clone(_.get(objectValue, "pictures", {})),
          stock: _.get(objectValue, "stock", 0),
          is_primary: _.get(objectValue, "is_primary", indexItem1 == 0),
        };
      });
      row = newValues;
    }
    secondProductFields.set(row);
    console.log(secondProductFields);
    setShowTableRow(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (id) {
      if (isMounted.current) isFetchingOnProduct.set(true);
      ProductCommerceRepository.show(id, brand?.id, {
        with: "channelRequestLog",
      })
        .then((response: any) => {
          if (isMounted.current) {
            isFetchingOnProduct.set(false);
            if (response.data.tokopedia_product.data) {
              setCountSold(
                _.get(
                  response.data.tokopedia_product.data[0],
                  "GMStats.countSold",
                  0
                )
              );
            }
          }
          const product =
            response.data.data.channel_request_log.data.products[0];
          const raw = response.data.data.channel_request_log.data.raw_data[0];
          if (product?.preorder?.is_active) {
            informationSetValue(
              "preorder.is_active",
              product.preorder.is_active
            );
            informationSetValue("preorder.duration", product.preorder.duration);
            informationSetValue(
              "preorder.time_unit",
              preOrderDayOptions.find((x: any) => {
                return x.id === product.preorder.time_unit;
              })
            );
          } else {
            informationSetValue(
              "preorder.is_active",
              _.get(product, "preorder.is_active", false)
            );
          }
          tokopediaProductId.set(parseInt(response.data.data.external_id));

          ProductRepository.show(
            parseInt(response.data.data.external_id),
            brand?.id
          ).then((resp: any) => {
            if (resp.data.data.data) {
              informationSetValue(
                "price",
                resp.data.data?.data[0]?.price.value
              );
              stockProduct.set(resp.data.data?.data[0]?.main_stock);
              setSelectedCategory1(resp.data.data?.data[0]?.categoryTree[0]);
              setSelectedCategory2(resp.data.data?.data[0]?.categoryTree[1]);
              informationSetValue(
                "category_id",
                resp.data.data?.data[0]?.categoryTree[2]
              );
            }
          });

          setTimeout(() => {
            informationSetValue(
              "weight_unit",
              defaultWeightOptions.find((x: any) => {
                return x.value == product.weight_unit;
              })
            );
            informationSetValue("name", product.name);
            informationSetValue("description", product.description);
            const tempPictures: any[] = [];
            product.pictures.forEach((item: any) => {
              tempPictures.push({
                url: item.file_path,
              });
            });
            tempPictures.map((temp: any, index: number) => {
              return setSecondPictureFields((item) => {
                const s = [...item];
                s[index] = temp;
                return s;
              });
            });
            if (raw?.selectedOption0) {
              setSelectedCategory1(raw?.selectedOption0);
              setSelectedCategory2(raw?.selectedOption1);
              selectedCategory1Options.set(raw?.selectedOption0);
              selectedCategory2Options.set(raw?.selectedOption1);
              informationSetValue("category_id", raw.selectedOption2);
            } else {
              selectedCategory1Options.set(raw?.select1);
              selectedCategory2Options.set(raw?.select2);
            }

            informationSetValue("category_id", raw.selectedOption2);
            informationSetValue("dimension.length", product.dimension.length);
            informationSetValue("dimension.width", product.dimension.width);
            informationSetValue("dimension.height", product.dimension.height);
            informationSetValue("condition", product.condition);
            // informationSetValue("status", product.status);
            // stockProduct.set(product.stock);
            statusProduct.set(product.status);
            informationSetValue("sku", product.sku);
            informationSetValue("etalase", raw.etalase);
            if (product?.videos) {
              informationSetValue(
                "videos",
                product?.videos.length > 0 &&
                  `https://www.youtube.com/watch?v=${product.videos[0].url}`
              );
            }
            informationSetValue("min_order", product.min_order);
            // informationSetValue("price", product.price);
            if (product.wholesale) {
              hasWholesale.set(true);
              product.wholesale.forEach((item: any) => {
                wholesaleAppend({
                  min_qty: item.min_qty,
                  price: item.price,
                });
              });
            }
            informationSetValue("weight", product.weight);
            if (product.variant) {
              const { products } = raw;
              ProductRepository.showWithVariant(
                parseInt(response.data.data.external_id),
                brand?.id
              ).then((resp: any) => {
                resp.data?.data?.data?.children.map(
                  (item: any, index: number) => {
                    return products[0][index].stock ? item?.stock : 2;
                  }
                );
                resp.data?.data?.data?.children.map(
                  (item: any, index: number) => {
                    return products[0][index].price ? item?.price : 0;
                  }
                );
              });
              const variant = product?.variant?.selection.map(
                (item: any, idx: number) => {
                  return {
                    variant_details: raw?.selection[idx],
                    variant_unit_id: item?.variant_unit_id,
                    options: item?.options,
                  };
                }
              );
              informationSetValue("selection", variant);
              secondProductFields.set(raw?.products[0]);
              setShowTableRow(true);
            }
          }, 200);
        })
        .catch((err: any) => {
          isFetchingOnProduct.set(false);
        });
    }
  };

  useEffect(() => {
    ShowcaseRepository.all(brand.id)
      .then((resp: AxiosResponse) => {
        showcases.set(resp.data.data.data.showcase);
      })
      .catch((err: AxiosError) => {});
  }, []);

  const addWholesale = () => {
    wholesaleAppend(WholesaleBaseModel);
  };

  const quantityLimit = ({ floatValue }: any) => {
    if (floatValue) {
      return (
        floatValue <=
        Number(_.get(preorder, "time_unit.id") === "WEEK" ? 13 : 90)
      );
    } else {
      return true;
    }
  };

  const onInformationSubmit = informationHandleSubmit((data) => {
    const formData = formatFormData(data);
    let errors = [];
    if (isMounted.current) {
      informationLoading.set(true);
      setDurationError(false);
      setCategoryError(false);
      setSkuError(false);
      setShowcaseError(false);
      dimensionErrors.length.set(false);
      dimensionErrors.width.set(false);
      dimensionErrors.height.set(false);
      setPictureNull(false);
      setNameError(false);
      setWeightError(false);
      setPriceError(false);
      setMinOrderError(false);
    }

    let products: any[] = [];
    let rawData: any[] = [
      {
        selection: [],
        etalase: data.etalase,
        selectedOption0: selectedCategory1,
        selectedOption1: selectedCategory2,
        selectedOption2: category,
        products: [],
      },
    ];

    if (!id && !formData.name) {
      errors.push("name");
    }

    if (!formData.min_order) {
      errors.push("min_order");
    }

    if (!formData.weight) {
      errors.push("weight");
    }

    if (!formData.dimension.length) {
      errors.push("dimension.length");
    }

    if (!formData.dimension.width) {
      errors.push("dimension.width");
    }

    if (!formData.dimension.height) {
      errors.push("dimension.height");
    }

    if (formData.products || formData.selection) {
      formData?.selection.forEach((select: any) => {
        rawData[0]?.selection.push(select?.variant_details);
      });
    }

    if (!formData.etalase) {
      errors.push("showcase");
    }

    formData.etalase = {
      id: formData.etalase,
    };

    formData.weight_unit = formData.weight_unit.value;
    formData.weight = parseInt(formData.weight);
    formData.price_currency = "IDR";

    formData.status = statusProduct.value;

    if (!id) {
      formData.status = "EMPTY";
      formData.stock = -1;
    }

    if (formData.category_id === "0" || !formData.category_id) {
      errors.push("category");
    }

    if (!formData.products && formData.sku === "") {
      errors.push("sku");
    }

    formData.category_id =
      typeof category?.id === "number" ? category?.id : parseInt(category?.id);

    const tempPictures: any[] = [];
    const clonePictureFields = [...secondPictureFields];

    if (!clonePictureFields[0].url) {
      errors.push("picture");
    }

    if (clonePictureFields[0].url) {
      secondPictureFields.forEach((item: any, index: number) => {
        if (item.url) {
          tempPictures.push({
            file_path: item?.url,
          });
        }
      });
    }

    formData.pictures = tempPictures;

    formData.price = parseFloat(formData.price);
    formData.stock = parseInt(formData.stock);

    formData.min_order =
      typeof formData?.min_order === "number"
        ? formData?.min_order
        : parseFloat(formData?.min_order);

    if (secondProductFields.value && secondProductFields.value.length > 0) {
      rawData[0]?.products.push(secondProductFields.value);
      const isPrimaryProduct = secondProductFields.value.find(
        (product: any) => product.is_primary
      );
      const arrayPrice: any[] = [];
      secondProductFields.value.map((product: any) => {
        return arrayPrice.push(product.price);
      });
      formData.price = Math.min(...arrayPrice);
      formData.sku = isPrimaryProduct?.sku;
      secondProductFields.value.forEach((item: any) => {
        const stockInt =
          typeof item.stock === "string" ? parseInt(item.stock) : item.stock;
        products.push({
          is_primary: item.is_primary,
          value_id: item.value_id,
          combination: item.combination,
          isZeroStock: stockInt < 1,
          status: statusProduct.value,
          pictures: [{ file_path: item.pictures.url }],
          stock: id && stockInt != 0 ? stockInt : 1,
          sku: item.sku,
          price:
            typeof item.price === "string" ? parseInt(item.price) : item.price,
        });
      });
    }

    if (formData.selection) {
      formData?.selection?.forEach((item: any, index: number) => {
        formData.selection[index].id = item?.variant_details?.variant_id;
        formData.selection[index].unit_id = item?.variant_unit_id;
        let options = JSON.parse(JSON.stringify(formData.selection[index].options))
        delete formData.selection[index].options;
        options?.forEach((item2 : any, index2 : number) => {
            options[index2].hex_code = item2.hex;
            options[index2].unit_value_id = item2.variant_unit_value_id;
            delete options[index2].status;
            delete options[index2].equivalent_value_id;
            delete options[index2].english_value;
            delete options[index2].icon;
            delete options[index2].variant_unit_value_id;
            delete options[index2].hex;
        });
        formData.selection[index].options = options;
        delete formData.selection[index].variant_details;
        delete formData.selection[index].variant_unit_id;
      });

      formData.variant = {
        products: products,
        selection: formData.selection,
      };

      delete formData.selection;
    }

    if (formData.dimension) {
      formData.dimension = {
        length: formData.dimension.length
          ? parseFloat(formData.dimension.length)
          : 0,
        width: formData.dimension.width
          ? parseFloat(formData.dimension.width)
          : 0,
        height: formData.dimension.height
          ? parseFloat(formData.dimension.height)
          : 0,
      };
    }

    if (formData?.preorder?.is_active) {
      formData.preorder = {
        time_unit: formData.preorder.time_unit.id,
        is_active: formData.preorder.is_active,
        duration: parseInt(formData.preorder.duration),
      };
    }

    if (formData.wholesale) {
      formData.wholesale.forEach((item: any) => {
        item.min_qty = parseInt(item.min_qty);
        item.price = parseFloat(item.price);
      });
    }

    if (formData.videos) {
      if (validateYoutubeUrl(formData.videos)) {
        formData.videos = [
          {
            source: "youtube",
            url: formData.videos.split("watch?v=")[1],
          },
        ];
      }
    }

    if (id) {
      formData.isZeroStock = false;
      formData.id = tokopediaProductId.value;
      formData.stock = stockProduct.value;
      if (typeof formData.pictures === "string") {
        formData.pictures = [
          {
            file_path: formData.pictures,
          },
        ];
      }
    }

    if (formData.stock < 1) {
      formData.isZeroStock = true;
      formData.stock = 1;
    }

    if (!formData.price) {
      errors.push("price");
    }

    if (formData.preorder.is_active && formData.preorder.duration <= 0) {
      errors.push("duration");
    }

    if (errors.length > 0) {
      if (errors.includes("duration")) {
        setDurationError(true);
      }
      if (errors.includes("dimension.length")) {
        dimensionErrors.length.set(true);
      }
      if (errors.includes("dimension.width")) {
        dimensionErrors.width.set(true);
      }
      if (errors.includes("dimension.height")) {
        dimensionErrors.height.set(true);
      }
      if (errors.includes("showcase")) {
        setShowcaseError(true);
      }
      if (errors.includes("category")) {
        setCategoryError(true);
      }
      if (errors.includes("sku")) {
        setSkuError(true);
      }
      if (errors.includes("picture")) {
        setPictureNull(true);
      }
      if (errors.includes("name")) {
        setNameError(true);
      }
      if (errors.includes("min_order")) {
        setMinOrderError(true);
      }
      if (errors.includes("weight")) {
        setWeightError(true);
      }
      if (errors.includes("price")) {
        setPriceError(true);
      }
      informationLoading.set(false);
      return;
    }

    (id
      ? ProductCommerceRepository.update(id, brand?.id, {
          products: [formData],
          raw_data: rawData,
        })
      : ProductCommerceRepository.create(brand.id, {
          products: [formData],
          raw_data: rawData,
        })
    )
      .then((resp: AxiosResponse) => {
        if (resp.data.tokopedia?.data?.failed_rows_data?.length > 0) {
          showToast(
            resp.data?.tokopedia?.data?.failed_rows_data[0]?.error[0],
            "error"
          );
        } else if (!resp.data.tokopedia.data) {
          showToast(resp.data.tokopedia.header.reason, "error");
        } else {
          showToast(resp.data.tokopedia.header.messages, "success");
          navigate(`/product`);
        }
        informationLoading.set(false);
      })
      .catch((err: AxiosError) => {
        if (isMounted.current && err?.message) {
          if (err?.response?.data?.errors) {
            const flattenErrors: any[] = Object.values(
              err?.response?.data?.errors
            ).flat();
            if (flattenErrors.length > 0) {
              showToast(flattenErrors[0], "error");
            }
          }
          informationLoading.set(false);
        }
      });
  });

  const breadcrumbList = [
    {
      label: t("Produk"),
      link: "/product",
    },
    {
      label: id ? t("general.edit") : t("general.create"),
      link: "/product/create",
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("products.title")}</title>
      </Helmet>

      <VuiBreadcrumb list={breadcrumbList} />

      <div className={"page-header-component"}>
        <div className="title-action-wrapper">
          <h3 className={"title"}>{title}</h3>
        </div>
      </div>

      {isFetchingOnProduct.get() ? (
        <VuiLoadingScreen />
      ) : (
        <form className={"form-wrapper"} onSubmit={onInformationSubmit}>
          <div className="col-md-8 col-sm-12">
            <div className="card-paper mb-4">
              <div className="card-header">
                <h6 className="card-header-title">Informasi Produk</h6>
              </div>

              <div className="card-content">
                <div className={"form-group"}>
                  <label className={"form-label"}>
                    {t("products.form.name.label")}
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    ref={informationRegister}
                    readOnly={countSold > 0}
                  />
                  {nameError && (
                    <span className={"text-danger"}>
                      Kolom nama tidak boleh kosong
                    </span>
                  )}
                </div>

                <div className={"form-group"}>
                  <label className={"form-label"}>
                    {t("products.form.description.label")}
                  </label>
                  <textarea
                    name="description"
                    className="form-control"
                    rows={7}
                    ref={informationRegister}
                  />
                </div>

                <div className={"form-group"}>
                  <label className={"form-label"}>
                    {t("products.form.picture.label")}
                  </label>
                  <SortableList
                    items={secondPictureFields}
                    onSortEnd={handleOnSortEnd}
                    axis={"x"}
                    useDragHandle
                  />
                  {pictureNull && (
                    <span className={"text-danger"}>
                      Gambar tidak boleh kosong
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="card-paper mb-4">
              <div className="card-header">
                <h6 className="card-header-title">Group</h6>
              </div>

              <div className="card-content">
                <div className={"form-group"}>
                  <label className={"form-label"}>
                    {t("products.form.category.label")}
                  </label>

                  <div className="row">
                    <div className="col-sm-12 col-md-4">
                      <Select
                        onChange={(value) => {
                          setSelectedCategory2(null);
                          informationSetValue("category_id", null);
                          setSelectedCategory1($clone(value));
                          selectedCategory1Options.set($clone(value));
                        }}
                        value={selectedCategory1}
                        options={categories.get()}
                        getOptionLabel={(value: any) => value["name"]}
                        getOptionValue={(value: any) => value["name"]}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <Select
                        onChange={(value) => {
                          informationSetValue("category_id", null);
                          setSelectedCategory2($clone(value));
                          selectedCategory2Options.set($clone(value));
                        }}
                        value={selectedCategory2}
                        options={selectedCategory1Options?.child.get()}
                        getOptionLabel={(value: any) => value["name"]}
                        getOptionValue={(value: any) => value["name"]}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <Controller
                        name={"category_id"}
                        control={informationControl}
                        defaultValue={"0"}
                        render={({ value, onChange }) => {
                          return (
                            <Select
                              onChange={(value) => {
                                setSelectedCategory3($clone(value));
                                selectionRemove();
                                onChange(value);
                              }}
                              value={value}
                              options={selectedCategory2Options?.child.get()}
                              getOptionLabel={(value: any) => value["name"]}
                              getOptionValue={(value: any) => value["name"]}
                            />
                          );
                        }}
                      />
                    </div>
                    {categoryError && (
                      <span className={"text-danger"}>
                        Kolom kategori tidak boleh kosong
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-paper mb-4">
              <div className="card-header">
                <h6 className="card-header-title">Detail Produk</h6>
              </div>

              <div className="card-content">
                <div className={"form-group"}>
                  <label className={"form-label"}>
                    {t("products.form.condition.label")}
                  </label>
                  {renderTypeConditions()}
                </div>
                <div className={"form-group"}>
                  <label className={"form-label"}>
                    {t("products.form.showcase.label")}
                  </label>
                  <Controller
                    name={"etalase"}
                    control={informationControl}
                    defaultValue={selectedShowcase.value}
                    render={(props) => {
                      return (
                        <Select
                          onChange={(value) => {
                            selectedShowcase.set($clone(value));
                            props.onChange($clone(value));
                          }}
                          value={props.value}
                          defaultValue={props.value}
                          options={showcases.value}
                          getOptionLabel={(value: any) => value["name"]}
                          getOptionValue={(value: any) => value["name"]}
                        />
                      );
                    }}
                  />
                  {showcaseError && (
                    <span className={"text-danger"}>
                      Etalase tidak boleh kosong
                    </span>
                  )}
                </div>
                {selectionFields?.length < 1 && (
                  <div className={"form-group"}>
                    <label className={"form-label"}>
                      {t("products.form.sku.label")}
                    </label>
                    <input
                      type="text"
                      name="sku"
                      className="form-control"
                      ref={informationRegister}
                    />
                    {skuError && (
                      <span className={"text-danger"}>
                        Kolom sku tidak boleh kosong
                      </span>
                    )}
                  </div>
                )}
                <div className={"form-group"}>
                  <label className={"form-label"}>
                    {t("products.form.videos.label")}
                  </label>
                  <input
                    type="text"
                    name="videos"
                    className="form-control"
                    ref={informationRegister}
                  />
                </div>
              </div>
            </div>
            <div className="card-paper mb-4">
              <div className="card-header">
                <h6 className="card-header-title">Varian</h6>
              </div>

              <div className="card-content">
                {selectionFields?.map((item, index: number) => {
                  return (
                    <div className="row" key={item?.key}>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className={"form-label"}>
                            {t("products.form.variantType.label")}
                          </label>
                          <Controller
                            name={`selection[${index}].variant_details`}
                            control={informationControl}
                            defaultValue={item?.variant_details}
                            render={(props) => {
                              return (
                                <Select
                                  onChange={(value) => {
                                    informationSetValue(
                                      `selection[${index}].options`,
                                      null
                                    );
                                    informationSetValue(
                                      `selection[${index}].variant_unit_id`,
                                      value?.units[0]?.variant_unit_id
                                    );
                                    handleChangeVariantOptions([], index);
                                    props.onChange(value);
                                  }}
                                  value={props?.value}
                                  defaultValue={props?.value}
                                  options={
                                    arrVariant.value.filter(
                                      (x: any) => {
                                        return (
                                          selection?.findIndex((a: any) => {
                                            return (
                                              a?.variant_details?.variant_id ===
                                              x?.variant_id
                                            );
                                          }) == -1
                                        );
                                      }
                                    ) || []
                                  }
                                  getOptionLabel={(value: any) => value["name"]}
                                  getOptionValue={(value: any) => value["name"]}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="form-group">
                          {selection?.map((selected: any, idx: number) => {
                            return (
                              <div key={idx}>
                                {index == idx && (
                                  <>
                                    <Controller
                                      render={({ onChange, value, name }) => {
                                        return (
                                          <div className="variant-unit-wrapper">
                                            {selected?.variant_details?.units?.map(
                                              (item: any, i: number) => {
                                                return (
                                                  <div
                                                    className="variant-unit"
                                                    key={i}
                                                  >
                                                    <div
                                                      className={"form-check"}
                                                    >
                                                      <div
                                                        className={
                                                          item?.unit_name === "" ||
                                                          !item?.unit_name
                                                            ? "opacity-0"
                                                            : "d-flex align-items-center"
                                                        }
                                                      >
                                                        <input
                                                          type="radio"
                                                          value={item?.variant_unit_id}
                                                          name={name}
                                                          className="form-check-input"
                                                          onChange={(value) => {
                                                            informationSetValue(
                                                              `selection[${index}].options`,
                                                              null
                                                            );
                                                            handleChangeVariantOptions(
                                                              [],
                                                              index
                                                            );
                                                            onChange(value);
                                                          }}
                                                          checked={
                                                            value ==
                                                            item?.variant_unit_id
                                                          }
                                                        />
                                                        <label
                                                          className={
                                                            "form-check-label"
                                                          }
                                                        >
                                                          {item?.unit_name}
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        );
                                      }}
                                      name={`selection[${index}].variant_unit_id`}
                                      control={informationControl}
                                      defaultValue={item?.variant_unit_id}
                                    />
                                    {selected?.variant_details?.units?.length >
                                      0 && (
                                      <Controller
                                        name={`selection[${index}].options`}
                                        control={informationControl}
                                        defaultValue={item?.options}
                                        render={(props) => {
                                          return (
                                            <Select
                                              onChange={(e: any) => {
                                                handleChangeVariantOptions(
                                                  e,
                                                  index
                                                );
                                                props.onChange(e);
                                              }}
                                              value={props?.value}
                                              options={
                                                selected?.variant_details?.units[
                                                  selected?.variant_details?.units?.findIndex(
                                                    (x: any) => {
                                                      return (
                                                        x?.variant_unit_id ==
                                                        selected?.variant_unit_id
                                                      );
                                                    }
                                                  )
                                                ]?.unit_values
                                              }
                                              isMulti={true}
                                              getOptionLabel={(value: any) =>
                                                value["value"]
                                              }
                                              getOptionValue={(value: any) =>
                                                value["value"]
                                              }
                                            />
                                          );
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="btn-action-wrapper">
                          <FontAwesomeIcon
                            className={"icon-delete-file"}
                            icon={faTrash}
                            onClick={() => {
                              selectionRemove(index);
                              selection.splice(index, 1);
                              if (selection?.length >= 1) {
                                handleChangeVariantOptions(
                                  selection[0]?.options,
                                  index
                                );
                              } else {
                                handleChangeVariantOptions([], index);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="varian-wrapper">
                  {isAdministrator() || isKeyAccount()
                    ? selectionFields?.length <= 1 && (
                        <button
                          type={"button"}
                          className="btn btn-primary"
                          disabled={!category?.id}
                          onClick={() => {
                            setShowTableRow(false);
                            selectionAppend({
                              variant_details: null,
                              variant_unit_id: null,
                              options: null,
                            });
                          }}
                        >
                          Tambah Varian
                        </button>
                      )
                    : null}

                  {selection?.length > 0 && (
                    <div className="react-bootstrap-table table-responsive mt-4">
                      <table className="table table-bordered table-custom">
                        <thead>
                          <tr>
                            {selection?.map((item: any, index: number) => {
                              return (
                                <th key={index}>{item?.variant_details?.name}</th>
                              );
                            })}
                            <th>Harga</th>
                            {/*<th>Stock</th>*/}
                            <th>SKU</th>
                            <th>GAMBAR</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {showTableRow &&
                            secondProductFields?.value.map(
                              (item: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    {typeof item?.name === "string" ? (
                                      <td>
                                        <div className={"form-group"}>
                                          <p>{item?.name}</p>
                                        </div>
                                      </td>
                                    ) : (
                                      item?.name?.map(
                                        (itemName: any, idx: number) => (
                                          <td key={idx}>
                                            <div className={"form-group"}>
                                              <p>{itemName}</p>
                                            </div>
                                          </td>
                                        )
                                      )
                                    )}
                                    <td>
                                      <InputGroup>
                                        <InputGroup.Prepend>
                                          <InputGroup.Text id="basic-addon1">
                                            Rp
                                          </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        {/*<FormControl*/}
                                        {/*  type={"text"}*/}
                                        {/*  value={item?.price}*/}
                                        {/*  onChange={(e) => {*/}
                                        {/*    secondProductFields[*/}
                                        {/*      index*/}
                                        {/*    ]?.price.set(e.target.value);*/}
                                        {/*  }}*/}
                                        {/*/>*/}
                                        <NumberFormat
                                          className="form-control"
                                          value={item.price}
                                          defaultValue={item.price}
                                          allowNegative={false}
                                          thousandSeparator={"."}
                                          decimalSeparator={","}
                                          decimalScale={0}
                                          placeholder={"0"}
                                          onValueChange={({ value }) => {
                                            secondProductFields[
                                              index
                                            ]?.price.set(value);
                                          }}
                                        />
                                      </InputGroup>
                                    </td>
                                    <td>
                                      <div className={"form-group"}>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={item?.sku}
                                          onChange={(e) =>
                                            secondProductFields[index]?.sku.set(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="image-table-wrapper">
                                        <VuiUploadImage
                                          defaultValue={$clone(item?.pictures)}
                                          onChange={(value) => {
                                            secondProductFields[
                                              index
                                            ].pictures.set($clone(value));
                                          }}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-group">
                                        {item?.is_primary && (
                                          <div>
                                            <p>Utama</p>
                                          </div>
                                        )}
                                        <button
                                          type={"button"}
                                          className={
                                            item?.is_primary
                                              ? "btn btn-hidden"
                                              : "btn btn-primary"
                                          }
                                          onClick={() => {
                                            secondProductFields.forEach(
                                              (field: any, i: number) => {
                                                field.is_primary.set(false);
                                              }
                                            );
                                            secondProductFields[
                                              index
                                            ].is_primary.set(!item?.is_primary);
                                          }}
                                        >
                                          Set Utama
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="card-paper mb-4">
              <div className="card-header">
                <h6 className="card-header-title">Harga</h6>
              </div>

              <div className="card-content">
                <div className={"form-group"}>
                  <label className={"form-label"}>
                    {t("products.form.minOrder.label")}
                  </label>
                  <Controller
                    name={"min_order"}
                    control={informationControl}
                    defaultValue={"1"}
                    render={(props) => (
                      <NumberFormat
                        className="form-control"
                        value={props.value}
                        data={props.value}
                        allowNegative={false}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        decimalScale={0}
                        placeholder={"0"}
                        onValueChange={({ value }) => {
                          props.onChange(value);
                        }}
                      />
                    )}
                  />
                  {minOrderError && (
                    <span className={"text-danger"}>
                      Kolom minimum pembelian tidak boleh kosong
                    </span>
                  )}
                </div>

                <Form.Check
                  id="grosir"
                  type="checkbox"
                  label={t("products.form.hasWholesale.label")}
                  checked={hasWholesale.value}
                  onChange={(val) => {
                    hasWholesale.set(val.target.checked);
                  }}
                />

                {secondProductFields.value.length <= 0 && (
                  <div className={"form-group mt-4"}>
                    <label className={"form-label"}>
                      {t("products.form.price.label")}
                    </label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Rp</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Controller
                        name={"price"}
                        control={informationControl}
                        defaultValue={null}
                        render={(props) => (
                          <NumberFormat
                            className="form-control"
                            value={props.value}
                            data={props.value}
                            allowNegative={false}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={0}
                            placeholder={"0"}
                            onValueChange={({ value }) => {
                              props.onChange(value);
                            }}
                          />
                        )}
                      />
                    </InputGroup>
                    {priceError && (
                      <span className={"text-danger"}>
                        Kolom harga utama tidak boleh kosong
                      </span>
                    )}
                  </div>
                )}

                {hasWholesale.value && (
                  <div className="grosir-wrapper">
                    {wholesaleFields.map((item: any, index: number) => {
                      return (
                        <div className="row" key={index}>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label className={"form-label"}>
                                {t("products.form.minWholesale.label")}
                              </label>
                              <InputGroup>
                                <InputGroup.Prepend>
                                  <InputGroup.Text id="basic-addon1">
                                    Pcs
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Controller
                                  name={`wholesale[${index}].min_qty`}
                                  control={informationControl}
                                  defaultValue={item.min_qty}
                                  render={(props) => (
                                    <NumberFormat
                                      className="form-control"
                                      value={props.value}
                                      data={props.value}
                                      allowNegative={false}
                                      thousandSeparator={"."}
                                      decimalSeparator={","}
                                      decimalScale={0}
                                      placeholder={"0"}
                                      onValueChange={({ value }) => {
                                        props.onChange(value);
                                      }}
                                    />
                                  )}
                                />
                              </InputGroup>
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="form-group">
                              <label className={"form-label"}>
                                {t("products.form.priceWholesale.label")}
                              </label>
                              <Controller
                                name={`wholesale[${index}].price`}
                                control={informationControl}
                                defaultValue={item.price}
                                render={(props) => (
                                  <NumberFormat
                                    className="form-control"
                                    value={props.value}
                                    data={props.value}
                                    allowNegative={false}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    decimalScale={0}
                                    placeholder={"0"}
                                    onValueChange={({ value }) => {
                                      props.onChange(value);
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="btn-action-wrapper">
                              <FontAwesomeIcon
                                className={"icon-delete-file"}
                                icon={faTrash}
                                onClick={() => wholesaleRemove(index)}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {wholesaleFields.length <= 4 && (
                      <button
                        type={"button"}
                        className="btn btn-primary"
                        onClick={() => addWholesale()}
                      >
                        Tambah Grosir
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="card-paper mb-4">
              <div className="card-header">
                <h6 className="card-header-title">Inventaris</h6>
              </div>

              <div className="card-content">
                <div className={"form-group"}>
                  <label className={"form-label"}>
                    {t("products.form.productWeight.label")}
                  </label>

                  <div className="row">
                    <div className="col-md-5 col-sm-12">
                      <Controller
                        name={"weight_unit"}
                        control={informationControl}
                        defaultValue={defaultWeightOptions[0]}
                        render={(props) => {
                          return (
                            <Select
                              onChange={props.onChange}
                              value={props.value}
                              defaultValue={props.value}
                              options={defaultWeightOptions}
                              getOptionLabel={(value) => value["label"]}
                              getOptionValue={(value) => value["label"]}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-7 col-sm-12">
                      <input
                        type="number"
                        name="weight"
                        placeholder={"Masukan Berat"}
                        className="form-control"
                        ref={informationRegister}
                        onWheel={(e) => {
                          e.currentTarget.blur();
                        }}
                      />
                      {weightError && (
                        <span className={"text-danger"}>
                          Kolom berat produk tidak boleh kosong
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className={"form-group"}>
                  <label className={"form-label"}>
                    {t("products.form.productSize.label")}
                  </label>
                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <InputGroup>
                        <FormControl
                          name={"dimension.length"}
                          type={"number"}
                          placeholder={"Panjang"}
                          ref={informationRegister}
                          onWheel={(e: any) => {
                            e.currentTarget.blur();
                          }}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text id="basic-addon2">
                            cm
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      {dimensionErrors.length.get() && (
                        <span className={"text-danger"}>
                          Kolom panjang produk tidak boleh kosong
                        </span>
                      )}
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <InputGroup>
                        <FormControl
                          name={"dimension.width"}
                          type={"number"}
                          placeholder={"Lebar"}
                          ref={informationRegister}
                          onWheel={(e: any) => {
                            e.currentTarget.blur();
                          }}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text id="basic-addon2">
                            cm
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      {dimensionErrors.width.get() && (
                        <span className={"text-danger"}>
                          Kolom lebar produk tidak boleh kosong
                        </span>
                      )}
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <InputGroup>
                        <FormControl
                          name={"dimension.height"}
                          type={"number"}
                          placeholder={"Tinggi"}
                          ref={informationRegister}
                          onWheel={(e: any) => {
                            e.currentTarget.blur();
                          }}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text id="basic-addon2">
                            cm
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      {dimensionErrors.height.get() && (
                        <span className={"text-danger"}>
                          Kolom tinggi produk tidak boleh kosong
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-paper mb-4">
              <div className="card-header">
                <h6 className="card-header-title">Pre Order</h6>
              </div>

              <div className="card-content">
                <div className={"form-group mb-unset"}>
                  <div className="switch-wrapper">
                    <Controller
                      name={"preorder.is_active"}
                      control={informationControl}
                      defaultValue={false}
                      render={(props) => {
                        return (
                          <ReactSwitch
                            checked={props.value}
                            onColor={"#101C51"}
                            onChange={props.onChange}
                            checkedIcon={false}
                            uncheckedIcon={false}
                          />
                        );
                      }}
                    />
                  </div>
                </div>

                {_.get(preorder, "is_active") && (
                  <div className={"form-group mt-4"}>
                    <label className={"form-label"}>
                      {t("products.form.timeProses.label")}
                    </label>
                    <div className="row">
                      <div className="col-md-5 col-sm-12">
                        <Controller
                          name={"preorder.duration"}
                          defaultValue={""}
                          control={informationControl}
                          render={(props) => {
                            return (
                              <NumberFormat
                                className={"form-control"}
                                allowEmptyFormatting={true}
                                allowNegative={false}
                                decimalScale={0}
                                placeholder={"0"}
                                value={props.value}
                                defaultValue={props.value}
                                isAllowed={quantityLimit}
                                onValueChange={({ value }) => {
                                  props.onChange(value);
                                }}
                              />
                            );
                          }}
                        />
                        {_.get(preorder, "time_unit.id") === "WEEK" ? (
                          <span>Maksimum 13 minggu</span>
                        ) : (
                          <span>Maksimum 90 hari</span>
                        )}
                        {durationError && (
                          <div className={"text-danger"}>
                            Waktu proses tidak boleh kosong
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12">
                        <Controller
                          name={"preorder.time_unit"}
                          control={informationControl}
                          defaultValue={preOrderDayOptions[0]}
                          render={(props) => {
                            return (
                              <Select
                                onChange={(value) => {
                                  informationSetValue("preorder.duration", "");
                                  props.onChange(value);
                                }}
                                value={props.value}
                                options={preOrderDayOptions}
                                getOptionLabel={(value: any) => value["name"]}
                                getOptionValue={(value: any) => value["id"]}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {isAdministrator() || isKeyAccount() ? (
            <div className={"card-action-form"}>
              <h6 className={"title"}>Simpan Data?</h6>

              <div className="btn-action-wrapper">
                <Link to={"/product"} className={"btn btn-sm btn-secondary"}>
                  Batal
                </Link>
                <VuiButton
                  forSubmit={true}
                  label={"Simpan"}
                  loading={informationLoading.get()}
                />
              </div>
            </div>
          ) : null}
        </form>
      )}
    </>
  );
};

export default ProductForm;
